@use 'mixins' as *;

.generic-plug {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  aspect-ratio: 1 / 1;

  a {
    inline-size: 100%;
    max-inline-size: 80%;
    padding-inline: 1em;
    &:hover {
      img {
        opacity: 0.95;
      }
    }
  }

  &__background-black {
    background: var(--color-black);
  }
}

.content-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  block-size: 100%;

  &__align-left {
    justify-content: flex-start;
  }

  &__align-right {
    justify-content: flex-end;
  }
}

.plug-big-title {
  @include line-height-percent(4.6rem);
  font-family: var(--font-league-gothic) !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  inline-size: 100%;
  letter-spacing: 2px;
  white-space: pre-wrap;
  display: inline-block;
  word-spacing: 100vw;
  filter: drop-shadow(1px 0 3px rgba(0, 0, 0, 0.22));

  @include breakpoint-min(tiny) {
    @include line-height-percent(5rem);
  }

  @media screen and (width >= 520px) {
    @include line-height-percent(7rem);
  }

  @media screen and (width >= 640px) {
    @include line-height-percent(8rem);
  }

  @include breakpoint-min(small) {
    @include line-height-percent(5rem);
  }

  @media screen and (width >= 894px) {
    @include line-height-percent(6rem);
  }

  &__col-2 {
    @include breakpoint-min(medium) {
      @include line-height-percent(7rem);
    }

    @include breakpoint-min(large) {
      @include line-height-percent(10rem);
    }

    @include breakpoint-min(max) {
      @include line-height-percent(13rem);
    }
  }

  &__col-3 {
    @include breakpoint-min(medium) {
      @include line-height-percent(4rem);
    }

    @include breakpoint-min(large) {
      @include line-height-percent(5.6rem);
    }

    @media screen and (width >= 1400px) {
      @include line-height-percent(6.6rem);
    }

    @media screen and (width >= 1600px) {
      @include line-height-percent(7.6rem);
    }

    @include breakpoint-min(max) {
      @include line-height-percent(8.4rem);
    }
  }

  &__col-4 {
    @include breakpoint-min(medium) {
      @include line-height-percent(3.1rem);
    }

    @include breakpoint-min(large) {
      @include line-height-percent(3.9rem);
    }

    @media screen and (width >= 1500px) {
      @include line-height-percent(4.6rem);
    }

    @include breakpoint-min(max) {
      @include line-height-percent(6rem);
    }

    @media screen and (width >= 2000px) {
      @include line-height-percent(6.5rem);
    }
  }

  &__align-left {
    text-align: left;
  }

  &__align-right {
    text-align: right;
  }
}

.less-than-three {
  @include line-height-percent(4.3rem);

  @include breakpoint-min(tiny) {
    @include line-height-percent(6rem);
  }

  @media screen and (width >= 520px) {
    @include line-height-percent(8rem);
  }

  @media screen and (width >= 570px) {
    @include line-height-percent(8.9rem);
  }

  @media screen and (width >= 620px) {
    @include line-height-percent(10rem);
  }

  @media screen and (width >= 700px) {
    @include line-height-percent(11.2rem);
  }

  @include breakpoint-min(small) {
    @include line-height-percent(5.6rem);
  }

  @media screen and (width >= 830px) {
    @include line-height-percent(6.4rem);
  }

  @media screen and (width >= 894px) {
    @include line-height-percent(7rem);
  }

  &__col-2 {
    @include breakpoint-min(medium) {
      @include line-height-percent(8.2rem);
    }

    @media screen and (width >= 1100px) {
      @include line-height-percent(8.3rem);
    }

    @include breakpoint-min(large) {
      @include line-height-percent(10rem);
    }

    @media screen and (width >= 1300px) {
      @include line-height-percent(11rem);
    }

    @media screen and (width >= 1400px) {
      @include line-height-percent(12rem);
    }

    @media screen and (width >= 1600px) {
      @include line-height-percent(14rem);
    }

    @media screen and (width >= 1880px) {
      @include line-height-percent(14.8rem);
    }
  }

  &__col-3 {
    @include breakpoint-min(medium) {
      @include line-height-percent(5rem);
    }

    @media screen and (width >= 1100px) {
      @include line-height-percent(5.3rem);
    }

    @include breakpoint-min(large) {
      @include line-height-percent(6.2rem);
    }

    @media screen and (width >= 1300px) {
      @include line-height-percent(6.8rem);
    }

    @media screen and (width >= 1400px) {
      @include line-height-percent(7.6rem);
    }

    @media screen and (width >= 1600px) {
      @include line-height-percent(8.5rem);
    }

    @media screen and (width >= 1880px) {
      @include line-height-percent(10.5rem);
    }

    @include breakpoint-min(max) {
      @include line-height-percent(11rem);
    }

    @media screen and (width >= 2000px) {
      @include line-height-percent(11.6rem);
    }
  }

  &__col-4 {
    @include breakpoint-min(medium) {
      @include line-height-percent(3.2rem);
    }

    @media screen and (width >= 1100px) {
      @include line-height-percent(3.8rem);
    }

    @include breakpoint-min(large) {
      @include line-height-percent(4.3rem);
    }

    @media screen and (width >= 1300px) {
      @include line-height-percent(4.8rem);
    }

    @media screen and (width >= 1400px) {
      @include line-height-percent(5.3rem);
    }

    @media screen and (width >= 1600px) {
      @include line-height-percent(6.3rem);
    }

    @media screen and (width >= 1880px) {
      @include line-height-percent(7.7rem);
    }

    @include breakpoint-min(max) {
      @include line-height-percent(7.7rem);
    }

    @media screen and (width >= 2000px) {
      @include line-height-percent(8.3rem);
    }
  }
}

.cta-box {
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  max-inline-size: 305px;
  text-align: center;
  margin-block-end: var(--spacing-regular);
  background-color: var(--color-gray5);
  --size-factor: 100vw;
  font-size: clamp(1rem, calc(1rem + var(--size-factor)), 100rem);

  @include breakpoint-min(tiny) {
    --size-factor: 50vw;
  }

  @include breakpoint-min(medium) {
    max-inline-size: 25rem;
  }

  @include breakpoint-min(max) {
    max-inline-size: 30rem;
  }
}

.cta-text {
  padding-block: 1.6em;
  appearance: none;
  max-width: 350px;
  min-width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: var(--font-size-header-3-content);
  line-height: var(--line-heitht-header-3-content);
  font-weight: var(----font-weight-medium);
  background-color: var(--color-gray5);
  color: var(--color-black);

  @include breakpoint-min(medium) {
    padding-block: 2em;
  }
}
