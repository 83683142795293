@use 'mixins' as *;

.generic-plug-block {
  display: grid;
  padding-inline: var(--spacing-small);
  max-inline-size: calc(100vw - 40px);
  margin-inline: auto;

  &__full-width {
    inline-size: 100%;
    padding-inline: 0;
    max-inline-size: unset;
  }

  &__gap {
    gap: var(--spacing-small);
    margin-block: var(--spacing-small);
  }

  @include breakpoint-min(small) {
    margin-inline: auto;

    &__grid-two {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__grid-three {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__grid-four {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @include breakpoint-min(medium) {
    span {
      overflow: hidden;

      a img {
        transition: 0.3s ease-in-out;
      }

      a:hover img {
        transform: scale(1.02);
      }
    }

    &__grid-three {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &__grid-four {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

.text-content-link {
  inline-size: 100%;
  text-decoration: none;
  h1,
  h2,
  h3,
  h4 {
    font-size: var(--font-size-h3);
  }
}
